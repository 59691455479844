.social-links {
    border-top: 3px solid var(--dividerColor);
    /* padding-top:; */
    justify-content: center !important;

}
.nav-tabs {
    align-content: center;
}
.footer-container {
   /* background-color: var(--backgroundColor1); */
   display: flex;
}

@media screen and (max-width: 428px) {
    .footer-container {
        /* background-color: var(--backgroundColor2); */
    }
  }




