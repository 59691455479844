:root {
  --textColor1: rgba(17, 224, 231, 0.9);
  --textColor2: rgba(158, 208, 133, 0.9);
  --altTextcolor: rgba(122, 49, 128, 0.9);
  --linkTextcolor: rgba(192, 225, 231, 0.9);
  --buttonColor: rgba(162, 127, 135, 0.9);
  --buttonColorAlt: rgba(231, 224, 17, 0.9);
  --backgroundColor1: rgba(234, 159, 106, 0.89);
  --backgroupColor2: rgba(249, 136, 55, 0.89);
  --backgroundColor3: rgba(176, 180, 228, 0.9);
  --glowColor: rgba(0, 0, 0, 0.9);
  --shadowBoxcolor: rgba(244, 236, 32, 0.9);
}

.nav-tabs {
    justify-content: right !important;
    /* background-color: green; */
}


/* .social-links {
    justify-content: center !important;
} */
.app-header-title {
    justify-content: left !important;
    border-width: 1px;
    border-color: #137ae1;
    padding: 7px 14px;
    margin: 0px 0px -1px;
    color: var(--textColor1);
    font-size: 30px;
    /* background-color: green; */

}



@media screen and (max-width: 428px) {
    .app-header-title {
        justify-content: center !important;
        display: flex;
        border-width: 1px;
        border-color: #137ae1;
        padding: 7px 14px;
        margin: 0px 0px -1px;
        color: var(--textColor2);
        font-size: 30px;
        /* background-color: green; */
    
    }

    .nav-tabs {
        justify-content: center !important;
        /* background-color: green; */
    }
}