
  
  .profile-pic {
    width: 200px;
    float: left;
    clear: both;
    border-radius: 10px;
    padding-bottom: 100px;
    margin-top: 100px;
    margin-bottom: 150px;
    margin-right: 10px;

  }

  @media screen and (max-width: 2560px) {
    
  }
  @media screen and (max-width: 820px) {
    .profile-pic {
        margin-bottom: 200px;
     }
    }
  
    
  @media screen and (max-width: 728px) {
    .profile-pic {
        width: 300px;
        justify-content: center;
        float: center;
        display: block;
        border-radius: 10px;
        padding-bottom: 1px;
        margin-top: -2px;
        margin-bottom: 1px;
        margin-right: 1px;
    }

    .about-figure-image{
        justify-content: center;
        display: flex;
        margin-top: 5%;
    }
  }