@media screen and (max-width: 2560px) {
 #proficiencies-hr{
    margin-left: 2%;
    margin-right: 2%;
 }   
 .proficiencies-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;

 }
}
    



@media screen and (max-width: 919px) {
    .proficiency-list-title {
        /* justify-content: center;
        text-align: center;
        display: flex; */
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
    }
    #proficiencies-hr{
        margin-left: 0%;
        margin-right: 0%;
     }   
     .proficiencies-container{
        margin-left: 0%;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        flex-direction: column;
     }
}

/* @media screen and (max-width: 828px) {

} */


@media screen and (max-width: 428px) {
   .proficiencies-container {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        align-items: center;
        justify-content: center;
        margin-top: 10%;
    }
    #proficiencies-hr{
        margin-left: 0;
        margin-right: 0;
     }   
     .proficiencies-container{
        margin-left: 0;
     }
     .proficiency-list-title {
        /* justify-content: center;
        text-align: center;
        display: flex; */
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
    }
}

