.project-container {
  /* display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3rem; */
}

.projects {
    background-size: cover;
    background-repeat: no-repeat;
    object-position: center;
    transition: 0.4s;
    width: 400px;
    margin: 10px auto 10px;
    position: relative;
    padding-bottom: 5rem;
    border-radius: 4px;
  }
  
  .custom-portfolio-container {
    background-color: var(--backgroundColor2) !important;
    -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    box-shadow: 2px 4px 6px var(--shadowBoxcolor);
    overflow: auto;
    margin: 48px auto 128px;
    width: 80% !important;
  }

.project-title-text{
    color: var(--textColor3);
    align-items: center;
    margin-bottom: 0;
    margin-top: 0;
    align-content: center;
  }
  
  .projects:hover {
    box-shadow: 0 0 50px var(--glowColor), inset 0 0 0 1000px rgba(255, 255, 255, 0.3);
    -webkit-box-shadow: 0 0 50px var(--glowColor), inset 0 0 0 1000px rgba(255, 255, 255, 0.3);
    -moz-box-shadow: 0 0 50px var(--glowColor), inset 0 0 0 1000px rgba(255, 255, 255, 0.3);
  }
  
  .text-container {
    background-color: var(--backgroundColor3);
    margin: 50% 10% 0;
    border-radius: 6px;
  }

  .projects:hover .project-title-text {
    display:none;
  }


  .projects .app-links a{
    display:none;
  }

  .projects:hover .app-links a{
    display:block;

  }

  .projects #project-title-container {
    align-items: center;
    align-content: center;

    margin-top: 45%;
    
  }

  /* .app-links:hover {
    display:show;
  } */

  @media screen and (max-width: 820px) {
    .projects {
      width: 90%;
    }
  
    .custom-portfolio-container {
      margin: 0 auto 80px;
      width: 100% !important;
    }
  }
  
  @media screen and (max-width: 828px) {

  }







  @media screen and (max-width: 428px) {
    .projects {
      border-radius: 8px;
      border: 0.5px solid;
    }
  
    .text-container {
      border: 0.5px solid;
      width: 100%;
      margin: 0;
    }
  
    .custom-portfolio-container {
      margin-bottom: 4px;
    }

      .projects {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      transition: 0.4s;
      width: 400px;
      margin: 10px auto 10px;
      height: 350px;
      position: relative;
      padding-bottom: 5rem;
      }
    .app-links{
      align-items: center;
      margin: 0 auto;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      flex-direction: row-reverse;

      transition: 0.5s ease;
      
    }
  }